

export default [
    {
        name: 'environmentHdrTexture',
        type: 'RGBEtexture',
        path: 'textures/environmentMap/hdr.hdr'
    },
    {
        name: 'moonColorTexture',
        type: 'texture',
        path: 'textures/moon/Dirt_BaseColor.jpg'
    },
    {
        name: 'moonAmbientOcclusionTexture',
        type: 'texture',
        path: 'textures/moon/Dirt_AmbientOcclusion.jpg'
    },
    {
        name: 'moonHeightTexture',
        type: 'texture',
        path: 'textures/moon/Dirt_Height.png'
    },
    {  
        name: 'moonNormalTexture',
        type: 'texture',
        path: 'textures/moon/Dirt_Normal.jpg'
    },
    {  
        name: 'moonRoughnessTexture',
        type: 'texture',
        path: 'textures/moon/Dirt_Roughness.jpg'
    },
    {
        name: 'landerModel',
        type: 'gltfModel',
        path: 'models/Lander/lander.glb'
    },
    {
        name: 'sevModel',
        type: 'gltfModel',
        path: 'models/Sev/sev.glb'
    },
    {
        name: 'moonModel',
        type: 'gltfModel',
        path: 'models/Moon/moon.gltf'
    },
    {
        name: 'gantryModel',
        type: 'gltfModel',
        path: 'models/Gantry/gantry.glb'
    },
    {
        name: 'hsuModel',
        type: 'gltfModel',
        path: 'models/HSU/hsu.glb'
    },
    {
        name: 'z2Model',
        type: 'gltfModel',
        path: 'models/Z2/z2.glb'
    },
    {
        name: 'helvetiker',
        type: 'font',
        path: 'fonts/helvetiker_regular.typeface.json'
    },
    {
        name: 'cloud',
        type: 'texture',
        path: 'textures/cloud/cloud.png'
    },
]